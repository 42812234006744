
import React from "react";
import axios from "axios";
import Snippet from "./Snippet.js";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";

const snippetsURL = "https://api.codenut.com/v1/codenut-microservice?TableName=Snippets";

export default class SnippetList extends React.Component {
  state = {
    snippets: [],
    title: '',
    code: '',
    language: ''
  };

  componentDidMount() {
    axios.get(snippetsURL).then(response => {
        const snippets = response.data.Items;        
        this.setState({snippets: snippets });
    });
  }

  handleTitleChange = event => {
    this.setState({ title: event.target.value });
    console.log(`"Setting title to ${event.target.value}"`);
  }

  handleCodeChange = event => {
    this.setState({ code: event.target.value });
    console.log(`"Setting code to ${event.target.value}"`);
  }

  handleLanguageChange = event => {
    this.setState({ language: event.target.value });
    console.log(`"Setting language to ${event.target.value}"`);
  }

  handleSubmit = event => {
    event.preventDefault();
    const id = Math.floor(Math.random() * 10000000);
    const snippet = {TableName:"Snippets", Item: 
      {
      title: {"S": this.state.title},
      code: {"S": this.state.code},
      language: {"S": this.state.language},
      id: {"S": `${id}`}
      }
    };

    console.log(`"posting snippet: ${JSON.stringify(snippet)}`)
    axios.post(snippetsURL, snippet).then(res => {
        console.log("Response data: " + res.data);
        this.componentDidMount();
      })
  }

  render() {
    const snippets = this.state.snippets.map( snippet => {
      return <Snippet key={snippet.id.S} title={snippet.title !== undefined ? snippet.title.S:""} language={snippet.language.S} code={snippet.code.S}/>;
    });
    return (
      <div>
        <section className="Snippets">{snippets}</section>
        <hr />
        <h3>Add Snippet</h3>
        <Form onSubmit={this.handleSubmit}>
          <Form.Label>Language:</Form.Label>
          <Form.Control as="select" onChange={this.handleLanguageChange}>
            <option>C</option>
            <option>Docker</option>
            <option>JavaScript</option>
            <option>Python</option>
            <option>Ruby</option>
          </Form.Control>
          <Form.Label>Title: </Form.Label>
          <Form.Control type="text" name="title" onChange={this.handleTitleChange} />
          <Form.Label>Code: </Form.Label>
          <Form.Control
            as="textarea"
            rows="3"
            name="code"
            onChange={this.handleCodeChange}
          />
          <button type="submit">Add Snippet</button>
        </Form>
      </div>
    );
  }
}