
const convertCodeToHtml = (language, codeText) => {
  let outputHtml = "";

  if (codeText.length > 0) {
    for (let counter = 0; counter < codeText.length; counter++) {
      switch (codeText[counter]) {
        case "\n":
          outputHtml += "<br/>";
          break;
        case "\t":
          outputHtml += "&nbsp;&nbsp;&nbsp;&nbsp;";
          break;
        case ">":
          outputHtml += "&gt;";
          break;
        case "<":
          outputHtml += "&lt;";
          break;
        default:
          outputHtml += codeText[counter];
      }
    }
  }
  return outputHtml;
};

export default convertCodeToHtml;