
import React from 'react';
import './Snippet.css';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import getCodeSnippet from './codeConverter';

const Snippet = (props) => {
    const showTabs = false;
    if(showTabs)
    {
    return (
      <div className="Snippet">
        <b>{props.title}</b><br />
        <Tabs defaultActiveKey={props.language}>
          <Tab eventKey={props.language} title={props.language}>
            <div className="Snippet" dangerouslySetInnerHTML={{ __html: getCodeSnippet(props.language, props.code) }} />
          </Tab>
          <Tab eventKey="Text" title="Text">
            <div className="Snippet">
              {props.code}
            </div> 
          </Tab>
        </Tabs>
      </div>
    );
    }
    else
    {
      return (
        <div>
          <b>{props.title}</b> {props.language}
          <div className="Snippet" dangerouslySetInnerHTML={{ __html: getCodeSnippet(props.language, props.code) }} />
        </div>
      );
    }

}

export default Snippet;