import React from 'react';
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";

const SnippetNavBar = (props) => {
    return (
        <Navbar bg="light" expand="lg">
            {/* <Navbar.Brand href="#home">Home</Navbar.Brand> */}
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Nav className="mr-auto">
                <NavDropdown title="Language" id="basic-nav-dropdown">
                    <NavDropdown.Item href="#action/3.1">JavaScript</NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.2">Python</NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">Ruby</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action/3.4">Docker</NavDropdown.Item>
                </NavDropdown>
            </Nav>
            <Navbar.Collapse id="basic-navbar-nav">
                <Form inline>
                    <FormControl type="text" placeholder="Search" className="mr-sm-2" />
                    <Button variant="outline-success">Search</Button>
                </Form>
            </Navbar.Collapse>        
            {/* <Nav.Link href="#add">Add Snippet</Nav.Link> */}
        </Navbar>
    );
}

export default SnippetNavBar;