import React from 'react';
import './App.css';
import SnippetList from './Snippet/SnippetList';
import SnippetNavBar from './components/SnippetNavBar/SnippetNavBar'

const title = "//CodeNut"

function App() {
  return (
    <div className="App">
      <header className="App-header">{title}</header>
      <SnippetNavBar />
      <SnippetList />
      <hr></hr>
      Built by <a href="https://prigital.com">prigital.com</a>
    </div>
  );
}

export default App;
